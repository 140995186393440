<template>
  <div id="app" ref="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
@import "../src/assets/font/font.css";
*{
  margin: 0;
  padding: 0;
}
ul,li{
  list-style: none;
}
html,
body,
#app{
  width: 100%;
  height: 100%;
  min-width: 400px;
}
/deep/ .el-menu{
  .el-submenu__title:hover,
  .el-menu-item:hover{
    background-color: transparent !important;
  }
  .el-menu--popup{
    .el-menu-item{
      background-color: transparent !important;
    }
  }
}
.alignLeft {
  text-align: left;
}
</style>
