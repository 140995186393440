import Vue from 'vue'
import App from './App'
import router from './router'
import 'animate.css/animate.min.css'
// import 'swiper/css/swiper.css'
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import './assets/js/main'
import VueMeta from 'vue-meta'
import axios from 'axios'

Vue.use(ElementUi, { size: 'small' })
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false
Vue.prototype.$axios = axios
// 配置axios的默认参数
axios.defaults.baseURL = 'https://www.bjblackhole.com/kaifazhe'
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
// 为每个页面添加title
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
// 退回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
