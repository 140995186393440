import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = [
  {
    path: '/',
    redirect: '/index',
    meta: {
      title: '上海秉匠信息科技有限公司'
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: resolve => require(['@/components/main.vue'], resolve),
    meta: {
      title: '上海秉匠信息科技有限公司'
    },
    children: [
      {
        // 首页
        path: '/index',
        name: 'Index',
        component: resolve => require(['@/components/index.vue'], resolve),
        meta: {
          title: '上海秉匠信息科技有限公司'
        }
      },
      {
        // 主营产品
        path: '/product',
        name: 'Product',
        component: resolve => require(['@/components/product/product.vue'], resolve),
        meta: {
          title: '主营产品'
        },
        children: [
          {
            path: '/productlist',
            name: 'Productlist',
            component: resolve => require(['@/components/product/productlist.vue'], resolve),
            meta: {
              title: '主营产品'
            }
          },
          {
            path: '/productinfoEngine',
            name: 'productinfoEngine',
            component: resolve => require(['@/components/product/productinfoEngine.vue'], resolve),
            meta: {
              title: '主营产品'
            }
          },
          {
            path: '/productinfoPlatform',
            name: 'productinfoPlatform',
            component: resolve => require(['@/components/product/productinfoPlatform'], resolve),
            meta: {
              title: '主营产品'
            }
          }
        ]
      },
      {
        // 星云管理平台
        path: '/productPlatform',
        name: 'productPlatform',
        component: resolve => require(['@/components/product/productPlatform.vue'], resolve),
        meta: {
          title: '星云管理平台'
        }
      },
      {
        // 黑洞图形引擎
        path: '/productEngine',
        name: 'productEngine',
        component: resolve => require(['@/components/product/productEngine.vue'], resolve),
        meta: {
          title: '黑洞图形引擎'
        }
      },
      {
        // 黑洞图形引擎
        path: '/productGalaxy',
        name: 'productGalaxy',
        component: resolve => require(['@/components/product/productGalaxy.vue'], resolve),
        meta: {
          title: '星系智慧建造平台'
        }
      },
      {
        // 应用案例
        path: '/case',
        name: 'Case',
        component: resolve => require(['@/components/case/case.vue'], resolve),
        meta: {
          title: '应用案例'
        }
      },
      {
        // 应用案例
        path: '/caseinfo',
        name: 'caseinfo',
        component: resolve => require(['@/components/case/caseinfo.vue'], resolve),
        meta: {
          title: '应用案例'
        }
      },
      {
        // 应用案例_昆阳路越江及配套道路工程
        path: '/caseinfo_kyl',
        name: 'caseinfo_kyl',
        component: resolve => require(['@/components/case/caseinfo_kyl.vue'], resolve),
        meta: {
          title: '应用案例'
        }
      },
      {
        // 应用案例_高铁地铁项目精装修
        path: '/caseinfo_gtdt',
        name: 'caseinfo_gtdt',
        component: resolve => require(['@/components/case/caseinfo_gtdt.vue'], resolve),
        meta: {
          title: '应用案例'
        }
      },
      {
        // 应用案例_车行山体隧道
        path: '/caseinfo_sd',
        name: 'caseinfo_sd',
        component: resolve => require(['@/components/case/caseinfo_sd.vue'], resolve),
        meta: {
          title: '应用案例'
        }
      },
      {
        // 应用案例_地铁项目
        path: '/caseinfo_dt',
        name: 'caseinfo_dt',
        component: resolve => require(['@/components/case/caseinfo_dt.vue'], resolve),
        meta: {
          title: '应用案例'
        }
      },
      {
        // 应用案例_污水处理厂项目
        path: '/caseinfo_ws',
        name: 'caseinfo_ws',
        component: resolve => require(['@/components/case/caseinfo_ws.vue'], resolve),
        meta: {
          title: '应用案例'
        }
      },
      {
        // 应用案例_现代有轨电车项目
        path: '/caseinfo_newgd',
        name: 'caseinfo_newgd',
        component: resolve => require(['@/components/case/caseinfo_newgd.vue'], resolve),
        meta: {
          title: '应用案例'
        }
      },
      {
        // 新闻中心列表
        path: '/news',
        name: 'News',
        component: resolve => require(['@/components/news/newsList.vue'], resolve),
        meta: {
          title: '新闻中心'
        }
      },
      {
        // 新闻中心详情
        path: '/newsDetails',
        name: 'newsDetails',
        component: resolve => require(['@/components/news/newsDetails.vue'], resolve),
        meta: {
          title: '新闻中心内容'
        }
      },
      {
        // 新闻中心详情——获奖
        path: '/newsDetails_new_chaotu',
        name: 'newsDetails_new_chaotu',
        component: resolve => require(['@/components/news/newsDetails_new_chaotu.vue'], resolve),
        meta: {
          title: '新闻中心内容'
        }
      },
      {
        // 新闻中心详情——获奖
        path: '/newsDetails_new_changan',
        name: 'newsDetails_new_changan',
        component: resolve => require(['@/components/news/newsDetails_new_changan.vue'], resolve),
        meta: {
          title: '新闻中心内容'
        }
      },
      {
        // 新闻中心详情——获奖
        path: '/newsDetails_new_hj',
        name: 'newsDetails_new_hj',
        component: resolve => require(['@/components/news/newsDetails_new_hj.vue'], resolve),
        meta: {
          title: '新闻中心内容'
        }
      },
      {
        // 新闻中心详情——南通
        path: '/newsDetails_new_nt',
        name: 'newsDetails_new_nt',
        component: resolve => require(['@/components/news/newsDetails_new_nt.vue'], resolve),
        meta: {
          title: '新闻中心内容'
        }
      },
      {
        // 新闻中心详情——CIM
        path: '/newsDetails_new_CIM',
        name: 'newsDetails_new_CIM',
        component: resolve => require(['@/components/news/newsDetails_new_cim.vue'], resolve),
        meta: {
          title: '新闻中心内容'
        }
      },
      {
        // 新闻中心详情——BIM
        path: '/newsDetails_new_BIM',
        name: 'newsDetails_new_BIM',
        component: resolve => require(['@/components/news/newsDetails_new_bim.vue'], resolve),
        meta: {
          title: '新闻中心内容'
        }
      },
      {
        // 新闻中心详情——东交
        path: '/newsDetails_new_dongjiao',
        name: 'newsDetails_new_dongjiao',
        component: resolve => require(['@/components/news/newsDetails_new_dongjiao.vue'], resolve),
        meta: {
          title: '新闻中心内容'
        }
      },
      {
        // 公司简介
        path: '/about',
        name: 'About',
        component: resolve => require(['@/components/about/aboutUs.vue'], resolve),
        meta: {
          title: '公司简介'
        }
      },
      {
        // 加入我们
        path: '/join',
        name: 'Join',
        redirect: '/position',
        component: resolve => require(['@/components/about/joinUs.vue'], resolve),
        meta: {
          title: '加入我们'
        },
        children: [
          // 职位列表
          {
            path: '/position',
            name: 'Position',
            component: resolve => require(['@/components/about/positionList.vue'], resolve),
            meta: {
              title: '职位列表'
            }
          },
          {
            path: '/bepending',
            name: 'bepending',
            component: resolve => require(['@/components/about/bePending.vue'], resolve),
            meta: {
              title: '职位列表'
            }
          }
        ]
      },
      {
        // 联系我们
        path: '/contact',
        name: 'Contact',
        component: resolve => require(['@/components/about/contactUs.vue'], resolve),
        meta: {
          title: '联系我们'
        }
      },
      {
        // 荣誉资质
        path: '/prize',
        name: 'Prize',
        component: resolve => require(['@/components/about/prize.vue'], resolve),
        meta: {
          title: '荣誉资质'
        }
      },
      // {
      //   // 关于我们
      //   path: '/aboutus',
      //   name: 'Aboutus',
      //   component: resolve => require(['@/components/about/about.vue'], resolve),
      //   meta: {
      //     title: '关于我们'
      //   }
      // },
      {
        // 职位详情前端
        path: '/positionDetails',
        name: 'positionDetails',
        component: resolve => require(['@/components/about/positionDetails.vue'], resolve),
        meta: {
          title: '职位详情'
        }
      },
      {
        // 职位详情java开发
        path: '/positionDetails_javakf',
        name: 'positionDetails_javakf',
        component: resolve => require(['@/components/about/positionDetails_javakf.vue'], resolve),
        meta: {
          title: '职位详情'
        }
      },
      {
        // 在线演示
        path: '/uitemp',
        name: 'uitemp',
        component: resolve => require(['@/components/uitemp/uitemp.vue'], resolve),
        meta: {
          title: '在线演示'
        }
      },
      {
        path: '/uitemplate',
        name: 'uitemplate',
        component: resolve => require(['@/components/uitemp/uitemplate.vue'], resolve),
        meta: {
          title: '在线演示'
        }

      },
      {
        // 下载
        path: '/download',
        name: 'download',
        component: resolve => require(['@/components/download/index.vue'], resolve),
        meta: {
          title: '下载'
        }
      },
      {
        // 下载中心
        path: '/downloadcenter',
        name: 'downloadcenter',
        component: resolve => require(['@/components/download/downloadcenter.vue'], resolve),
        meta: {
          title: '下载中心'
        }
      },
      {
        // 联系我们
        path: '/contactus',
        name: 'contactus',
        component: resolve => require(['@/components/download/contactus.vue'], resolve),
        meta: {
          title: '联系我们'
        }
      },
      {
        // 技术服务
        path: '/service',
        name: 'service',
        component: resolve => require(['@/components/service/index.vue'], resolve),
        meta: {
          title: '技术服务'
        }
      },
      {
        // 常见问题
        path: '/question',
        name: 'question',
        component: resolve => require(['@/components/service/question.vue'], resolve),
        meta: {
          title: '常见问题'
        }
      },
      {
        // 视频解析
        path: '/explain',
        name: 'explain',
        component: resolve => require(['@/components/service/explain.vue'], resolve),
        meta: {
          title: '视频解析'
        }
      }
    ]
  },
  {
    // ui在线演示模型页
    path: '/uitemplate',
    name: 'uitemplate',
    component: resolve => require(['@/components/uitemp/uitemplate.vue'], resolve),
    meta: {
      title: 'UI在线演示'
    }
  },
  {
    // 登录
    path: '/login',
    name: 'login',
    component: resolve => require(['@/components/login.vue'], resolve),
    meta: {
      title: '登录'
    }
  },
  {
    // 注册
    path: '/register',
    name: 'register',
    component: resolve => require(['@/components/register.vue'], resolve),
    meta: {
      title: '注册'
    }
  }
]
const router = new Router({
  routes
})
router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})
router.beforeEach((to, from, next) => {
  if (to.path === '/contactus') {
    window.open('https://wj.qq.com/s2/10302697/55cd/', '_blank')
    return
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
